import React, { useState, useEffect } from 'react';
let count = 0;
const TeamLinkItem = ({ name, levels, idx, schoolType, type }) => {
  const [genders, setGenders] = useState(undefined);

  useEffect( () => {
    let gendersSet = new Set();

    levels.forEach( level => gendersSet.add(level.gender) );
    let tempGender = Array.from(gendersSet);

    setGenders(tempGender);
  }, [])

  if (genders === undefined) return <div></div>;

  if (type === "Band") {
    return (
      <div className="col mb-0">
        <ul>
          <ListLinkBand levels={levels} idx={idx} name={name} genders={genders} schoolType={schoolType} />
        </ul>
      </div>
    )
  }

  return (
    <div className="col-4 col-md-3 col-lg-2">
      <h5 className="DetermineColor--allInOne" title={name}>{name}</h5>
      <ul className="d-flex justify-content-between">
        <ListLink levels={levels} idx={idx} name={name} genders={genders} schoolType={schoolType} />
      </ul>
    </div>
  )
}

export default TeamLinkItem;

// School teams and Club Teams

const ListLink = ({ levels, genders, name, idx, schoolType }) => {
  return (
    <>
    {
      genders.sort().map( gender => {
        switch(gender) {
          case 'mw':
            return (
              <li className="col-6" key={`${name}-${gender}-${idx}`}>
                <ul>
                  {
                    levels.map( (level, idx) => <TeamLinkLevel name={name} level={level} key={`${name}-level-${idx}`} />)
                  }
                </ul>
              </li>
            )
          case 'm':
            return (
              <li className="col-6" key={`${name}-${gender}-${idx}`}>
                <h6 title={ schoolType === "highschool" ? "Boys" : "Mens" }>{ schoolType === "highschool" ? "Boys" : "Mens" }</h6>
                <ul>
                  {
                    levels.map( (level, idx) => (level.gender === 'm') && <TeamLinkLevel name={name} level={level} key={`${name}-level-${idx}`} />)
                  }
                </ul>
              </li>
            )
          case 'w':
            return (
              <li className="col-6" key={`${name}-${gender}-${idx}`}>
                <h6 title={ schoolType === "highschool" ? "Girls" : "Womens" }>{ schoolType === "highschool" ? "Girls" : "Womens" }</h6>
                <ul>
                  {
                    levels.map( (level, idx) => (level.gender === 'w') && <TeamLinkLevel name={name} level={level} key={`${name}-level-${idx}`} />)
                  }
                </ul>
              </li>
            )
        }
      })
    }
    </>
  )
}

const TeamLinkLevel = ({ level, name }) => {
  return (
    <li className="dropdown-item"><a href={name === "Band" ? `/bands/${level.band_id}` : `/t/${level.id}`}>{
      level.level_name == 'Program' || name === "Band" ? (
        'Homepage'
      ) : (
        level.level_name
      )
    }</a></li>
  )
}

// Band Teams

const ListLinkBand = ({ levels, genders, name, idx }) => {
  return (
    <>
    {
      genders.sort().map( gender => {
        return (
          <li className="col" key={`${name}-${gender}-${idx}`}>
            <ul className="row p-3 BandGroups">
              {
                levels.map( (level, idx) => <TeamLinkBandName name={name} level={level} key={`${name}-level-${idx}`} />)
              }
            </ul>
          </li>
        )
      })
    }
    </>
  )
}

const TeamLinkBandName = ({ level }) => {
  return (
    <li className="BandGroup-item col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"><a href={`/t/${level.id}`}>{
      level.short_name
    }</a></li>
  )
}
