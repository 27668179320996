import ReactDOM from 'react-dom';
import preloaded from '../../src/preloaded.js';
import TeamListDisplay from '../../src/mainMenuListTeams/DisplayListOfTeamsAndLevels.jsx';

const navEl = document.getElementById('dropdownTeamList');
let network = {};
let year = null;
let type = null;
const setVars = (org, org_year, org_type) => {
  network = org;
  year = org_year;
  type = org_type;
}

if (preloaded.school) setVars(preloaded.school, preloaded.school_year, preloaded.type);
if (preloaded.club) setVars(preloaded.club, preloaded.club_year, preloaded.type);
if (preloaded.band) setVars(preloaded.band, preloaded.band_year, preloaded.type);

if (navEl) {
  ReactDOM.render(
    <TeamListDisplay
      network={network}
      current_year={year}
      type={type}
      el={navEl}
    />
  , navEl);
}
