import React, { useState, useEffect } from 'react';
import TeamLinkItem from './TeamLinkItem.jsx';
import * as qf from '../misc/qf';

import './DisplayListOfTeamsAndLevels.css';

const TeamListDisplay = ({ network, current_year, type, el }) => {
  const [teams, setTeams] = useState(undefined);
  const [sportNames, setSportNames] = useState(undefined);
  const [sportLevelLists, setSportLevelLists] = useState(undefined);

  const buildTeamList = () => {
    let sportNamesTemp = new Set();
    let listOfTeams = {};

    teams.forEach( team => sportNamesTemp.add(team.sport_name))
    sportNamesTemp = Array.from(sportNamesTemp).sort();
    sportNamesTemp.forEach( name => {
      listOfTeams[name] = teams.filter( team => team.sport_name === name );
    })
    setSportLevelLists(listOfTeams);
    setSportNames(sportNamesTemp);
  }

  const joinCreateTeam = () => {
    const joinCreatePage = `/schools/${network.id}/join`;
    window.location.href = joinCreatePage;
  }

  useEffect( () => {
    let url = '';
    
    if (type === "School") url = `/schools/${network.id}/teams.json`;
    if (type === "Club") url = `/clubs/${network.id}/teams.json`;
    if (type === "Band") url = `/bands/${network.id}/groups.json`;

    qf.getJSON(url)
      .then( res => {
        const currTeams = res.teams.filter( team => team.school_year === current_year );
        setTeams(currTeams);
      })
      .catch( err => console.error('Error', err));
  }, [])

  useEffect( () => {
    if (teams && teams.length === 0) {
      el.remove();
    }
    else if (teams !== undefined) {
      buildTeamList();
    }
  }, [teams])

  if (sportNames === undefined) return <div key={`${network.name}-1`}></div>;

  if (sportNames.length < 1) return <></>;

  return (
    <>
      <a className="dropdown-toggle" id="DropdownMenu--team" data-bs-toggle="dropdown" aria-expanded="false"></a>
      <ul className="dropdown-menu" aria-labelledby="DropdownMenu--team">
      <li>
        <div className="container pt-3 px-4">
          <div className="row">
            {
              sportNames.map( (name,idx) => <TeamLinkItem name={name} type={type} levels={sportLevelLists[name]} idx={idx} key={idx} schoolType={network.school_type} />)
            }
          </div>
          <div className="row">
            <div className="col text-center ButtonsTeam">
              <button className="btn btn-outline-light me-3 col-4" onClick={joinCreateTeam} title="Create a Team">
                <i className="fa-regular fa-plus me-2"></i><span>Create a Team</span>
              </button>
              <button className="btn btn-outline-light col-4" onClick={joinCreateTeam} title="Join Team">
                <i className="fa-regular fa-plus me-2"></i><span>Join Team</span>
              </button>
            </div>
          </div>
        </div>
      </li>
      </ul>
    </>
  )
}

export default TeamListDisplay;
